//
//
//
//  Created by Dimitri ALMON (DALM1) on 16/11/2023.
//  Copyright DALM AGENCY © - 2023
//

import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../firebase"; //--'
import Navbar from "./Navbar";
import Search from "./Search";
import Chats from "./Chats";
import { Link } from "react-router-dom";

const Sidebar = () => {
  const [selectedUserForChat, setSelectedUserForChat] = useState(null);
  const [userPhotoURL, setUserPhotoURL] = useState(null);

  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    const fetchUserPhoto = async () => {
      try {
        if (currentUser && currentUser.uid) {
          const q = query(
            collection(db, "users"),
            where("uid", "==", currentUser.uid)
          );
          const querySnapshot = await getDocs(q);

          querySnapshot.forEach((doc) => {
            setUserPhotoURL(doc.data().photoURL);
          });
        }
      } catch (error) {
        console.error("Error fetching user photo:", error);
      }
    };

    if (currentUser) {
      fetchUserPhoto();
    }
  }, [currentUser]);

  const handleUserSelected = (user) => {
    setSelectedUserForChat(user);
  };

  return (
    <div className="sidebar">
      <Link to="/usergest">
        <Navbar userPhotoURL={userPhotoURL} />
      </Link>
      <Search onUserSelected={handleUserSelected} />

      <Chats selectedUserForChat={selectedUserForChat} />
    </div>
  );
};

export default Sidebar;
