//
//
//
//  Created by Dimitri ALMON (DALM1) on 16/11/2023.
//  Copyright DALM AGENCY © - 2023
//

import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { updateProfile } from "firebase/auth";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { db, storage } from "../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import Dalm1 from "../img/dalm11.png";

const UserGest = () => {
  const { currentUser } = useContext(AuthContext);
  const [newDisplayName, setNewDisplayName] = useState(currentUser.displayName);
  const [newPhoto, setNewPhoto] = useState(null);
  // eslint-disable-next-line
  const [backgroundImage, setBackgroundImage] = useState("");

  const handleDisplayNameChange = (event) => {
    setNewDisplayName(event.target.value);
  };

  useEffect(() => {
    const fetchBackground = async () => {
      try {
        const userDocRef = doc(db, "users", currentUser.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setBackgroundImage(
            userData.backgroundImage || "/default-profile-picture.jpg"
          );
        }
      } catch (error) {
        console.error("Error fetching user's background:", error);
      }
    };

    fetchBackground();
  }, [currentUser.uid]);

  const userGestStyle = {
    // marginBottom: "3rem",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    // marginTop: "10rem",
    color: "white",
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
  };

  const updateDisplayName = async () => {
    const userDocRef = doc(db, "users", currentUser.uid);

    try {
      await updateDoc(userDocRef, {
        displayName: newDisplayName,
      });

      currentUser.displayName = newDisplayName;

      setNewDisplayName(newDisplayName);
    } catch (error) {
      console.error("Error updating display name:", error);
    }
  };

  const handleBackgroundChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const date = new Date().getTime();
      const storageRef = ref(storage, `${currentUser.uid + date}`);

      try {
        await uploadBytesResumable(storageRef, file);
        const downloadURL = await getDownloadURL(storageRef);

        const userDocRef = doc(db, "users", currentUser.uid);
        await updateDoc(userDocRef, {
          backgroundImage: downloadURL,
        });

        setBackgroundImage(downloadURL);
      } catch (error) {
        console.error("Error updating background image:", error);
      }
    }
  };
  const handlePhotoChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const displayName = currentUser.displayName;
      const uid = currentUser.uid;

      const date = new Date().getTime();
      const storageRef = ref(storage, `${displayName + date}`);

      try {
        await uploadBytesResumable(storageRef, file);

        const downloadURL = await getDownloadURL(storageRef);

        await updateProfile(currentUser, {
          photoURL: downloadURL,
        });

        const userDocRef = doc(db, "users", uid);
        await updateDoc(userDocRef, {
          photoURL: downloadURL,
        });

        setNewPhoto(downloadURL);
      } catch (error) {
        console.error("Error updating photo:", error);
      }
    }
  };

  const userImageStyle = {
    objectFit: "cover",
    width: "5rem",
    height: "5rem",
    marginBottom: "1.5rem",
    borderRadius: "50%",
    border: "2px solid white",
    cursor: "pointer",
  };

  return (
    <div>
      <Link to="/">
        <img src={Dalm1} alt="" />
      </Link>
      <div style={userGestStyle}>
        <label htmlFor="photoInput">
          <img
            style={userImageStyle}
            src={newPhoto || currentUser.photoURL}
            alt=""
          />
          <input
            type="file"
            id="photoInput"
            accept="image/*"
            style={{ display: "none" }}
            onChange={handlePhotoChange}
          />
        </label>
        <label htmlFor="backgroundInput">
          <input
            className=".buttonchangebag"
            type="file"
            id="backgroundInput"
            accept="image/*"
            style={{ display: "none" }}
            onChange={handleBackgroundChange}
          />
          <div
            className="buttonchangebag"
            style={{
              border: "2px solid white",
              padding: "0.5rem",
              borderRadius: "5px",
              cursor: "pointer",
              margin: "3rem",
            }}
          >
            Choose Background Image
          </div>
        </label>
        <input
          type="text"
          value={newDisplayName}
          onChange={handleDisplayNameChange}
          style={{
            border: "2px solid white",
            padding: "0.5rem",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        />
        <button
          style={{
            border: "2px solid white",
            padding: "0.5rem",
            borderRadius: "5px",
            cursor: "pointer",
            margin: "3rem",
          }}
          onClick={updateDisplayName}
        >
          Update Username
        </button>
      </div>
    </div>
  );
};

export default UserGest;
