//
//
//
//  Created by Dimitri ALMON (DALM1) on 16/11/2023.
//  Copyright DALM AGENCY © - 2023
//

import "./style.scss";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Cgu from "./pages/Cgu";
import Usergest from "./pages/Usergest";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "./context/AuthContext";
import Tcpchat from "./pages/Tcpchat";
import Userpage from "./pages/Userpage";
import Download from "./pages/Download";

function App() {
  const { currentUser } = useContext(AuthContext);

  const ProtectedRoute = ({ children }) => {
    if (!currentUser) {
      return <Navigate to="/login" />;
    }

    return children;
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route
            index
            element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            }
          />
          <Route path="tcpchat" element={<Tcpchat />} />
          <Route path="download" element={<Download />} />
          <Route path="userpage" element={<Userpage />} />
          <Route path="usergest" element={<Usergest />} />
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="cgu" element={<Cgu />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
