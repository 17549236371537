//
//
//
//  Created by Dimitri ALMON (DALM1) on 16/11/2023.
//  Copyright DALM AGENCY © - 2023
//

import React, { useState } from "react";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { auth, db, storage } from "../firebase"; //--'
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { doc, setDoc } from "firebase/firestore";
import { useNavigate, Link } from "react-router-dom";
import Spinner from "../components/Spinner";
import Add from "../img/addAvatar.png";
import Dalm1 from "../img/dalm11.png";

const Register = () => {
  const [err, setErr] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [acceptCGU, setAcceptCGU] = useState(false);
  const [uploading, setUploading] = useState(false);

  const handleSubmit = async (e) => {
    setUploading(true);
    setLoading(true);
    e.preventDefault();
    const displayName = e.target[0].value;
    const email = e.target[1].value;
    const password = e.target[2].value;
    const file = e.target[3].files[0];

    try {
      //Create user
      const res = await createUserWithEmailAndPassword(auth, email, password);

      //Create a unique image name
      const date = new Date().getTime();
      const storageRef = ref(storage, `${displayName + date}`);

      await uploadBytesResumable(storageRef, file).then(() => {
        getDownloadURL(storageRef).then(async (downloadURL) => {
          try {
            //Update profile
            await updateProfile(res.user, {
              displayName,
              photoURL: downloadURL,
            });
            //create user on firestore
            await setDoc(doc(db, "users", res.user.uid), {
              uid: res.user.uid,
              displayName,
              email,
              photoURL: downloadURL,
            });

            //create empty user chats on firestore
            await setDoc(doc(db, "userChats", res.user.uid), {});
            navigate("/");
          } catch (err) {
            console.log(err);
            setErr(true);
            setLoading(false);
            setUploading(false);
          }
        });
      });
    } catch (err) {
      setErr(true);
      setLoading(false);
      setUploading(false);
    }
  };

  return (
    <div className="formContainer">
      <div className="formWrapper">
        <span className="logo">
          <Link to="/">
            <img src={Dalm1} alt="" />
          </Link>
        </span>
        <span className="title">Register</span>
        <form className="registerform" onSubmit={handleSubmit}>
          <input required type="text" placeholder="display name" />
          <input required type="email" placeholder="email" />
          <input required type="password" placeholder="password" />
          <input required style={{ display: "none" }} type="file" id="file" />
          <label htmlFor="file">
            <img src={Add} alt="" />
          </label>
          <div className="space"></div>
          <div className="CGU">
            <label htmlFor="acceptCGU" className="acceptCGU">
              J'accepte les conditions générales d'utilisation
              <Link to="/cgu">CGU</Link>
            </label>
            <input
              className="registerform"
              type="checkbox"
              id="acceptCGU"
              onChange={(e) => setAcceptCGU(e.target.checked)}
            />
          </div>
          <button disabled={!acceptCGU || loading}>Sign up</button>
          {loading && (
            <span className="uploading-msg">
              Uploading and compressing the image please wait...
            </span>
          )}
          {uploading && (
            <span className="uploading-msg">
              <Spinner />
            </span>
          )}
          {err && <span className="form-err">Something went wrong :/</span>}
        </form>
        <p>
          You do have an account? <Link to="/login">Login</Link>
        </p>
      </div>
    </div>
  );
};

export default Register;
