//
//
//
//  Created by Dimitri ALMON (DALM1) on 16/11/2023.
//  Copyright DALM AGENCY © - 2023
//

import React, { useContext } from "react";
import Cam from "../img/cam.png";
import Add from "../img/add.png";
import More from "../img/more.png";
import Messages from "./Messages";
import Input from "./Input";
import { signOut } from "firebase/auth";
import { auth } from "../firebase"; //--'
import { Link } from "react-router-dom";
import { ChatContext } from "../context/ChatContext";

const Chat = () => {
  const { data } = useContext(ChatContext);

  const clearCache = async () => {
    // Clear the browser's cache
    await window.caches.delete("my-app-cache");
    localStorage.clear();
    // --------------------------------------------
  };

  return (
    <div className="chat">
      <div className="chatInfo">
        <Link to="/userpage">
          <span>{data.user?.displayName}</span>
        </Link>
        <div className="chatIcons">
          <button
            onClick={() => {
              signOut(auth);
              clearCache();
            }}
          >
            LOG OUT
          </button>
          <img src={Cam} alt="" className="camlogo" />
          <img src={Add} alt="" className="addlogo" />
          <Link to="/tcpchat">
            <img src={More} alt="" className="morelogo" />
          </Link>
        </div>
      </div>
      <Messages />
      <Input />
    </div>
  );
};

export default Chat;
