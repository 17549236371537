//
//
//
//  Created by Dimitri ALMON (DALM1) on 16/11/2023.
//  Copyright DALM AGENCY © - 2023
//

import React, { useContext, useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import "../components/Message";
import { db } from "../firebase"; // --'
import { Link } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { ChatContext } from "../context/ChatContext";
// eslint-disable-next-line
import DefaultBackground from "../img/defaultprofilepicture.gif";
import Dalm1 from "../img/dalm11.png";

const Userpage = (message) => {
  const { currentUser } = useContext(AuthContext);
  const { data } = useContext(ChatContext);
  const [userPhotoURL, setUserPhotoURL] = useState("");
  const [backgroundImage, setBackgroundImage] = useState(DefaultBackground);

  // eslint-disable-next-line
  const userGestStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "10rem",
    color: "white",
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    padding: "1rem",
    borderRadius: "10px",
  };

  const userImageStyle = {
    objectFit: "cover",
    width: "5rem",
    height: "5rem",
    marginBottom: "1.5rem",
    borderRadius: "50%",
    border: "2px solid white",
    cursor: "pointer",
  };

  const userNameStyle = {
    fontSize: "0.8rem",
    textAlign: "center",
  };

  useEffect(() => {
    const fetchUserPhoto = async () => {
      if (data.user && data.user.uid) {
        try {
          const userDoc = await getDoc(doc(db, "users", data.user.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setUserPhotoURL(userData.photoURL);
            setBackgroundImage(userData.backgroundImage || DefaultBackground);
          }
        } catch (err) {
          console.error("Error fetching user photo:", err);
        }
      }
    };

    fetchUserPhoto();
  }, [data.user]);

  return (
    <div>
      <Link to="/">
        <img src={Dalm1} alt="" />
      </Link>
      <div style={userGestStyle}>
        <label>
          <img
            style={userImageStyle}
            src={
              message.senderId === currentUser.uid
                ? currentUser.photoURL
                : userPhotoURL || currentUser.photoURL
            }
            alt=""
          />
        </label>
        <span style={userNameStyle}>{data.user.displayName}</span>
      </div>
    </div>
  );
};

export default Userpage;
