//
//
//
//  Created by Dimitri ALMON (DALM1) on 16/11/2023.
//  Copyright DALM AGENCY © - 2023
//

import React from "react";

function Spinner() {
  return (
    <div>
      <span className="loader"></span>
    </div>
  );
}

export default Spinner;
