//
//
//
//  Created by Dimitri ALMON (DALM1) on 16/11/2023.
//  Copyright DALM AGENCY © - 2023
//

import { doc, onSnapshot, getDoc } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import { ChatContext } from "../context/ChatContext";
import { db } from "../firebase";
import Message from "./Message";

const Messages = () => {
  const [messages, setMessages] = useState([]);
  const { data } = useContext(ChatContext);
  const [userPhotoURL, setUserPhotoURL] = useState("");

  useEffect(() => {
    const fetchUserPhoto = async () => {
      if (data.user && data.user.uid) {
        try {
          const userDoc = await getDoc(doc(db, "users", data.user.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setUserPhotoURL(userData.photoURL);
          }
        } catch (err) {
          console.error("Error fetching user photo:", err);
        }
      }
    };

    fetchUserPhoto();
  }, [data.user]);

  useEffect(() => {
    const unSub = onSnapshot(doc(db, "chats", data.chatId), (doc) => {
      if (doc.exists()) {
        setMessages(doc.data().messages);
      }
    });

    return () => {
      unSub();
    };
  }, [data.chatId]);

  return (
    <div className="messages">
      {messages.map((m) => (
        <Message message={m} key={m.id} userPhotoURL={userPhotoURL} />
      ))}
    </div>
  );
};

export default Messages;
