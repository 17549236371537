//
//
//
//  Created by Dimitri ALMON (DALM1) on 16/11/2023.
//  Copyright DALM AGENCY © - 2023
//

import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { collection, getDocs, doc, onSnapshot } from "firebase/firestore";
import { AuthContext } from "../context/AuthContext";
import { ChatContext } from "../context/ChatContext";
/* eslint-disable-next-line no-unused-vars */
import dalm1 from "../img/dalm1.png";
import { db } from "../firebase";
/* eslint-disable-next-line no-unused-vars */

const Chats = () => {
  const [chats, setChats] = useState([]);
  const { currentUser } = useContext(AuthContext);
  const { dispatch } = useContext(ChatContext);
  const [usersData, setUsersData] = useState({});

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersCollection = collection(db, "users");
        const unsubscribe = onSnapshot(usersCollection, (querySnapshot) => {
          querySnapshot.docChanges().forEach((change) => {
            if (change.type === "modified") {
              const userId = change.doc.id;
              const updatedUserData = change.doc.data();

              setUsersData((prevUsersData) => ({
                ...prevUsersData,
                [userId]: updatedUserData,
              }));
            }
          });
        });

        return () => {
          unsubscribe();
        };
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersCollection = collection(db, "users");
        const usersSnapshot = await getDocs(usersCollection);
        const users = {};
        usersSnapshot.forEach((doc) => {
          users[doc.id] = doc.data();
        });
        setUsersData(users);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    const getChats = async () => {
      const unsub = onSnapshot(doc(db, "userChats", currentUser.uid), (doc) => {
        setChats(Object.values(doc.data() || {}));
      });

      return () => {
        unsub();
      };
    };

    currentUser.uid && getChats();
  }, [currentUser.uid]);

  const handleSelect = async (u) => {
    dispatch({ type: "CHANGE_USER", payload: u });
  };

  const truncateText = (text, maxLength) => {
    if (!text) return "";

    return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
  };

  return (
    <div className="chats">
      {chats.map((chat) => (
        <div
          className="userChat"
          key={chat.userInfo.uid}
          onClick={() => handleSelect(chat.userInfo)}
        >
          <Link to="userpage">
            <img
              className="navuserimg"
              src={usersData[chat.userInfo.uid]?.photoURL || "/img/dalm1.png"}
              alt=""
            />
          </Link>
          <div className="userChatInfo">
            <span>{chat.userInfo.displayName}</span>

            <p>{truncateText(chat.lastMessage?.text, 35)}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Chats;
