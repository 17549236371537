//
//
//
//  Created by Dimitri ALMON (DALM1) on 16/11/2023.
//  Copyright DALM AGENCY © - 2023
//

import React, { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { ChatContext } from "../context/ChatContext";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";

const Message = ({ message, index, messages }) => {
  const { currentUser } = useContext(AuthContext);
  const { data } = useContext(ChatContext);
  const [senderPhotoURL, setSenderPhotoURL] = useState("");

  const ref = useRef();
  const options = {
    hour: "numeric",
    minute: "numeric",
    month: "numeric",
    day: "numeric",
    year: "numeric",
  };
  const time = new Date(message.date.toDate()).toLocaleTimeString(
    "fr-US",
    options
  );

  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  }, [message]);

  useEffect(() => {
    const fetchSenderPhoto = async () => {
      try {
        // Vérification de l'existence de data.user et récupération de la photo depuis la collection 'users'
        if (data.user && data.user.uid) {
          const userDoc = await getDoc(doc(db, "users", data.user.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setSenderPhotoURL(userData.photoURL);
          }
        }
      } catch (err) {
        console.error("Error fetching sender photo:", err);
      }
    };

    fetchSenderPhoto();
  }, [data.user]);

  return (
    <div
      ref={ref}
      className={`message ${
        message.senderId === currentUser.uid ? "owner" : ""
      }`}
    >
      <div className="messageInfo">
        <img
          src={
            message.senderId === currentUser.uid
              ? currentUser.photoURL
              : senderPhotoURL || currentUser.photoURL
          }
          alt=""
        />
        <span className="timemsg">{message.sender}</span>
        <span className="time">{time}</span>
      </div>
      <div className="messageContent">
        <p>{message.text}</p>
        {message.img && <img src={message.photoURL} alt="" />}
      </div>
    </div>
  );
};

export default Message;
