//
//
//
//  Created by Dimitri ALMON (DALM1) on 16/11/2023.
//  Copyright DALM AGENCY © - 2023
//

import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { db } from "../firebase";
import { doc, getDoc } from "firebase/firestore";
import Sidebar from "../components/Sidebar";
import Chat from "../components/Chat";

/* eslint-disable-next-line no-unused-vars */
import defaultprofilepiceture from "../img/defaultprofilepicture.gif";

const Home = () => {
  const { currentUser } = useContext(AuthContext);
  const [backgroundImage, setBackgroundImage] = useState("");

  useEffect(() => {
    const fetchBackground = async () => {
      try {
        const userDocRef = doc(db, "users", currentUser.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setBackgroundImage(
            userData.backgroundImage || "/img/defaultprofilepicture.gif"
          );
        }
      } catch (error) {
        console.error("Error fetching user's background:", error);
      }
    };

    if (currentUser && currentUser.uid) {
      fetchBackground();
    }
  }, [currentUser]);

  const userbackground = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  return (
    <div style={userbackground} className="home">
      <div className="container">
        <Sidebar />
        <Chat />
      </div>
    </div>
  );
};

export default Home;
