//
//
//
//  Created by Dimitri ALMON (DALM1) on 16/11/2023.
//  Copyright DALM AGENCY © - 2023
//

import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { AuthContext } from "../context/AuthContext";
import Dalm1 from "../img/dalm11.png";
import { db } from "../firebase";

// eslint-disable-next-line
import DefaultBackground from "../img/defaultprofilepicture.gif";

const Tcpchat = () => {
  const [downloadStatus, setDownloadStatus] = useState("idle");
  const [backgroundImage, setBackgroundImage] = useState("");
  const { currentUser } = useContext(AuthContext);

  const handleDownload = async () => {
    try {
      setDownloadStatus("downloading");

      const link = document.createElement("a");
      link.href = `${process.env.PUBLIC_URL}/TCPDALM1CHAT.exe`;
      link.download = "TCPDALM1CHAT.exe";
      link.click();

      setDownloadStatus("success");
    } catch (error) {
      console.error("Erreur lors du téléchargement du fichier :", error);
      setDownloadStatus("error");
    } finally {
      setDownloadStatus("idle");
    }
  };

  useEffect(() => {
    const fetchBackgroundImage = async () => {
      try {
        // eslint-disable-next-line
        const userDocRef = doc(db, "users", currentUser.uid);
        const userDoc = await getDoc(doc(db, "users", currentUser.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setBackgroundImage(userData.backgroundImage || "");
        }
      } catch (error) {
        console.error(
          "Erreur lors de la récupération du fond d'écran de l'utilisateur :",
          error
        );
      }
    };

    fetchBackgroundImage();
    // eslint-disable-next-line
  }, []);

  return (
    <div
      className="tcp-chat"
      style={{
        height: "110vh",
        // margin: "2rem",
        // marginBottom: "3rem",
        // paddingBottom: "10rem",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        // marginTop: "10rem",
        color: "white",
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
      }}
    >
      <Link to="/">
        <img src={Dalm1} style={{ margin: "8rem", with: "5rem" }} alt="" />
      </Link>
      <h1>TCPDALM|CHAT</h1>
      <span style={{ color: "red" }}>BETA TESTER ONLY</span>
      <button className="button-download-chat" onClick={handleDownload}>
        ⛧ Download TCPDALM|CHAT ⛧
      </button>
      <span style={{ color: "#09da73" }}>FREE ACCESS</span>
      <Link to="/download">
        <button className="button-download-chat">
          <span>Download DALM|CHAT software</span>
        </button>
      </Link>

      {downloadStatus === "downloading" && <p>Downloading...</p>}
      {downloadStatus === "success" && <p>Dowloaded !</p>}
      {downloadStatus === "error" && (
        <p>Error while downloading the file, please try later.</p>
      )}
    </div>
  );
};

export default Tcpchat;
