//
//
//
//  Created by Dimitri ALMON (DALM1) on 16/11/2023.
//  Copyright DALM AGENCY © - 2023
//

import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase";
import Dalm1 from "../img/dalm11.png";

const Login = () => {
  const [err, setErr] = useState(false);
  const navigate = useNavigate();

  const clearCache = async () => {
    await window.caches.delete("my-app-cache");
    localStorage.clear();
    // --------------------------------------------
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const email = e.target[0].value;
    const password = e.target[1].value;

    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate("/");
    } catch (err) {
      setErr(true);
    }
  };
  return (
    <div className="formContainer">
      <div className="formWrapper">
        <span className="logo">
          <img src={Dalm1} alt="" />
        </span>
        <span className="title">Login</span>
        <form onSubmit={handleSubmit} onClick={clearCache}>
          <input type="email" placeholder="email" />
          <input type="password" placeholder="password" />
          <button>Sign in</button>
          {err && <span className="form-err">Something went wrong :/</span>}
        </form>
        <p>
          You don't have an account? <Link to="/register">Register</Link>
        </p>
      </div>
    </div>
  );
};

export default Login;
