//
//
//
//  Created by Dimitri ALMON (DALM1) on 16/11/2023.
//  Copyright DALM AGENCY © - 2023
//

import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { db } from "../firebase";
import { getDoc, doc } from "firebase/firestore";
import Dalm1 from "../img/dalm11.png";
import Spinner from "./Spinner";

const Navbar = () => {
  // eslint-disable-next-line
  const [userProfile, setUserProfile] = useState(null);
  const { currentUser } = useContext(AuthContext);
  const [userPhotoURL, setUserPhotoURL] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);

    const fetchUserProfile = async () => {
      if (currentUser && currentUser.uid) {
        try {
          const userDoc = await getDoc(doc(db, "users", currentUser.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setUserProfile(userData);
            setUserPhotoURL(
              userData.photoURL ?? "/default-profile-picture.jpg"
            );
          }
        } catch (error) {
          console.error("Error fetching user profile:", error);
        }
      }

      setIsLoading(false);
    };

    fetchUserProfile();
  }, [currentUser]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="navbar">
      <span className="logo">
        <img src={Dalm1} alt="" />
      </span>
      <div className="user">
        <Link to="/usergest">
          <img src={userPhotoURL} alt="" />
        </Link>
        <Link to="/userpage">
          <span>{currentUser?.displayName}</span>
        </Link>
      </div>
    </div>
  );
};

export default Navbar;
